
import React from 'react';
// import CopyrightIcon from '@mui/icons-material/Copyright';

export default function Footer(){
    return (
        <div className="footer text-center" style={{ fontFamily: 'Abhaya Libre' }}>
            Built by Tyler Tran
        </div>
    );
}